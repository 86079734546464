<template>
  <v-container
    id="my-profile"
    fluid
    tag="section"
  >
    <v-overlay :value="isOverlayVisible">
      <v-progress-circular
        :size="70"
        :width="7"
        color="amber"
        indeterminate
        class="spinner"
      />
    </v-overlay>
    <v-row justify="center">
      <v-col
        cols="12"
        md="12"
      >
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Edit Profile
            </div>

            <!-- <div class="subtitle-1 font-weight-light">
              Complete your profile
            </div> -->
          </template>

          <v-form>
            <v-container class="py-0">
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-text-field
                    v-model="username"
                    label="Username"
                    class="purple-input"
                    disabled="true"
                  />
                </v-col>

                <v-col
                  cols="12"
                  class="text-right"
                >
                  <v-btn
                    color="success"
                    class="mr-0"
                    @click="save"
                  >
                    Update
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import HttpConnector from '../../../utils/http-connector'

  export default {

    data: () => ({
      httpConnector: new HttpConnector(),
      apiEndpoint: process.env.VUE_APP_API_BASE_URL,
      apiCode: process.env.VUE_APP_API_CODE,
      isOverlayVisible: false,
      username: '',
      twilioSid: '',
      twilioAuthToken: '',
      twilioSecret: '',
      isTwilioSidVisible: false,
      isTwilioAuthTokenVisible: false,
      isTwilioSecretVisible: false,
      headers: [
        { text: 'Transaction Id', value: 'transactionID' },
        { text: 'Amount', value: 'amount' },
        { text: 'Date', value: 'createDate' },
      ],
      transactions: [],
      user: null,
    }),

    async created () {
      this.usermail = localStorage.getItem('username')
      await this.initialize()
      await this.loadTransactions()
    },

    methods: {
      async setUserData (profileResponse) {
        this.user = profileResponse
        this.username = profileResponse.Username
        this.twilioSid = profileResponse.TwilioSID
        this.twilioAuthToken = profileResponse.TwilioToken
        this.twilioSecret = profileResponse.TwilioSecret
      },
      async save () {
        this.isOverlayVisible = true
        const payload = {
          username: this.username,
          TwilioAuthication: this.twilioSid,
          TwilioToken: this.twilioAuthToken,
          TwilioSecret: this.twilioSecret,
        }
        try {
          await this.httpConnector.makeRequest('post', `${this.apiEndpoint}updatetwiliocreds?code=${this.apiCode}`, payload)
          this.isOverlayVisible = false
        } catch (error) {
          this.isOverlayVisible = false
        }
      },
      async loadTransactions () {
        try {
          this.isOverlayVisible = true
          const { data: transactionsResponse } = await this.httpConnector.makeRequest('get', `${this.apiEndpoint}user/transactions?code=${this.apiCode}`)
          this.transactions = transactionsResponse || []
          this.isOverlayVisible = false
        } catch (error) {
          this.isOverlayVisible = false
          throw error
        }
      },
      async initialize () {
        try {
          this.isOverlayVisible = true
          const profileResponse = await this.httpConnector.makeRequest('get', `${this.apiEndpoint}userid?code=${this.apiCode}`)
          this.setUserData(profileResponse.data)
          this.isOverlayVisible = false
        } catch (error) {
          this.isOverlayVisible = false
          throw error
        }
      },
    },
  }

</script>
